.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-wrapper {
  /* min-height: calc(100vh - 95px);  */
  min-height: calc(100vh - 40px);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.actiongrp {
  position: absolute;
  height: 40px;
  /* background-color: #009b61; */
  top: -40px;
  left: 0;
  /* padding: 20px; */
  display: none;
  align-items: center;
  border-radius: 5px 5px 0 0;
  justify-content: space-evenly;
}

.profile-info li {
  display: flex !important;
}

.sectionFocusStyle:hover .actiongrp {
  display: flex;
}

.action-grp-profile {
  position: absolute;
  /* height: 80px;
	width: 40px; */
  /* background-color: #009b61; */
  left: -40px;
  top: 0;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-evenly;
}

.action-grp-summary {
  position: absolute;
  /* height: 40px;
	width: 40px; */
  /* background-color: #009b61; */
  left: -40px;
  top: 0;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-evenly;
}

/* *[contenteditable="true"]:hover {
  border: 2px dashed #009b61  ;
} */
.section-parent {
  transition: all 0.1s ease;
}

.inneractgrp {
  position: absolute;
  /* background-color: #009b61; */
  height: 180px;
  /* padding: 10px; */
  left: -35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px 0px 0px 5px;
  justify-content: space-evenly;
  display: none;
  top: 0px;
}

.inneractgrp > img,
.actiongrp > img,
.action-grp-summary img,
.action-grp-profile img {
  background-color: #009b61;
  width: 33px !important;
  padding: 10px;
  border-radius: 5px;
}

.inneractgrp .delete,
.actiongrp .delete {
  background-color: #ff3535;
}

.inneractgrp .drag-bg,
.actiongrp .drag-bg {
  background-color: #298efe;
}

.innersectiongrp:hover .inneractgrp {
  display: flex;
}

/* [contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
} */

/* For placeholder text Caution before change */
[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  font-style: bold;
  font-size: inherit !important;
  cursor: text;
  /* opacity: 0.5 !important; */
}

[data-placeholder]:focus:before {
  content: attr("");

  opacity: 0.3 !important;
}
/* .ql-editor.ql-blank:focus::before {
  content: "";
  opacity: 0.2 !important;
} */
/* [contenteditable][data-placeholder]:empty:before {
  content: attr(placeholder);
}
[contenteditable][placeholder]:empty:before {
  opacity: 0.5 !important;
}
[contenteditable][data-placeholder]:empty:focus:before {
  opacity: 0.2 !important;
} */
/* For placeholder text Caution before change */

.arrow-bg {
  background-color: #ffffff;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  margin: 3px 0;
}

.innersection-information h6 {
  color: #273e4c;
  font-size: 14px;
  /* font-weight: 500; */
  text-decoration: none;
  margin-bottom: 0;
}

.innersection-information p {
  color: #929aab;
  font-size: 13px;
}

.inneresection .ql-toolbar {
  border: 1px solid #019d61 !important;
  background: transparent;
  position: absolute;
  width: 55%;
  margin: 0 auto;
  border-radius: 5px;
  top: -20px;
  left: 50%;
  transform: translate(-50%, 0px);
  text-align: center;
}

.btn-link,
.btn-link:hover {
  text-decoration: none;
  border-radius: 6px;
  background-color: #ecf0f1;
}
.drag-bg {
  display: none;
  transition: all 0.5s ease;
}
.btn-link:hover .drag-bg {
  display: block;
}

#button-tooltip-2 > .tooltip-inner {
  background-color: #273e4c;
  color: #ffffff;
}

#button-tooltip-2 > .tooltip-arrow {
  border-top: 5px solid #273e4c;
}

@media (min-width: 1400px) {
  .editor-container {
    max-width: 1414px;
  }

  .editor_accordian {
    width: 100% !important;
    max-width: 350px !important;
  }
}

.cursor-link {
  cursor: pointer;
}
.offline-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.offline-h1 {
  color: #009b61;
  font-family: arial;
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 5px;
  line-height: 1rem;
  text-shadow: 0 0 3px white;
}
.offline-h4 {
  color: #009b61;
  font-family: arial;
  font-weight: 300;
  font-size: 16px;
}
.offline-button {
  display: block;
  padding: 15px 30px;
  background: #009b61;
  color: white;
  font-family: arial;
  letter-spacing: 5px;
  border-radius: 0.4rem;
  text-decoration: none;
  /* box-shadow: 0 0 15px #22254c; */
}
.rating-btn{
  background: #009b61;
    border-radius: 11px;
    padding: 4px 8px 4px 2px;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(24px);
    cursor: pointer;
    transition: .4s transform;
    width: 64px;
    height: 37px;
    display: flex;
    align-items: center;
    z-index: 9999999999;
    
}
.rating-smile {
  font-size: 20px;
  color: #fff;
  padding-left: 10px;

}
.rating-btn:hover {
  transform: translateY(-50%) translateX(14px);
}
.trustpilot-modal-footer-text{
  font-size: 13px;
}