/*Css Variables*/
.tab-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.tab-content::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.tab-content::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

:root {
  --White: #ffffff;
  --themebg: #edf0f1;
  --successcolor: #009c61;
  --primarycolor: #273e4c;
}

/*Common Styles*/
body {
  background-color: var(--themebg);
  font-family: "Inter", "Noto Sans KR", "Noto Serif KR";
  box-sizing: border-box;
}

a,
a:hover {
  color: var(--White);
}
.mainheader .profdrpdwn {
  background-color: transparent;
  border: none;
}
#resume-drpdown::after {
  border: none !important;
  display: none;
}
.mainheader .profdrpdwn img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.indexlink {
  color: blue;
}
:focus {
  outline: 0;
}
.resume-sidebar {
  display: none;
}
::placeholder {
  color: #929aab !important;
  font-size: 14px;
}

.form-control:focus {
  box-shadow: none;
}

.btn-success {
  background-color: #009c61 !important;
}
.show > .btn-primary > .dropdown-toggle,
.btn-primary {
  background-color: transparent !important;
  border: none !important;
  color: #273e4d;
}
.p-14 {
  padding: 14px !important;
}
.show > .btn-primary > .dropdown-toggle {
  color: #273e4d;
}

.show > .btn-primary > .dropdown-toggle:focus,
.btn-primary:focus {
  box-shadow: none !important;
  color: var(--primarycolor) !important;
}

.primarybtn {
  background-color: var(--White);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 14%);
  border: none;
  padding: 13px;
  font-size: 16px;
  font-weight: 700;
  color: var(--primarycolor);
}
.landimgcontent {
  margin-top: -110px !important;
}
.land-desc {
  max-width: 410px;
  margin: 0 auto;
}
.maintitle {
  font-size: 23px;
  font-weight: 300;
  color: #0e0f11;
  margin: 30px 0 10px 0px;
}
.termcontent {
  color: #273e4c;
  font-size: 14px;
}
.btn-primary:hover,
.btn-primary:active {
  color: #273e4e !important;
}
.titledesc {
  font-size: 16px;
  color: #0e0f11;
}
.primaryinput {
  background-color: var(--White);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 14%);
  border: none;
  padding: 14px 20px;
  border-bottom: 2px solid transparent !important;
}

.successbtn {
  background-color: var(--successcolor);
  color: var(--white);
  padding: 0 10px;
  font-weight: 500;
  line-height: 50px;
}

.primarycolor {
  color: #0fa169;
}

.addbtn {
  background-color: var(--white);
  border: 1px solid #929aab;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  padding: 0 15px;
  line-height: 34px;
  color: var(--primarycolor);
  transition: 0.5s;
}
.pluswhite {
  display: none;
}
.addbtn:hover .pluswhite {
  display: initial;
}
.addbtn:hover .plusgray {
  display: none;
}
.addbtn:hover {
  background-color: #929aab;
  color: var(--white);
}

.dashedbottom {
  border-bottom: 1px dashed #0fa169;
  border-width: 1px;
}
.titiesec .dashedbottom,
.titledesc .dashedbottom {
  cursor: pointer;
}

.loginlogos {
  max-width: 18px;
  margin-right: 8px;
}

.modal-body {
  padding: 40px 50px;
}

.modal-content {
  border-radius: 10px;
  overflow: hidden;
}
.togglebtn {
  position: absolute;
  top: 0;
  z-index: 999;
  left: 0;
  width: 25px;
  height: 50px;
  border: none;
  display: none;
  background-color: #ecf0f1;
}
.togglebtn {
  position: absolute;
  top: 0;
  z-index: 999;
  left: 0;
  width: 25px;
  height: 50px;
  border: none;
  display: none;
  background-color: #ecf0f1;
}
.togglebtn img {
  max-width: 15px;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 1;
}

.close_popup {
  position: absolute;
  top: -4px;
  right: -4px;
  opacity: 1;
}

.cus-formcontrol {
  border: none;
  padding: 14px;
}

.page-split {
  width: 100% !important;
  padding: 20px;
  background-color: #edf0f1;
}
label {
  color: var(--primarycolor);
  font-size: 13px;
}

.mainborder {
  border: 1px solid #929aab;
}

.probtn {
  font-size: 13px;
  color: #ffffff !important;
  background-color: #0fa169;
  position: absolute;
  font-weight: 500;
  right: 5px;
  top: 5px;
  padding: 5px !important;
  border-radius: 6px;
  display: flex;
  align-items: center;
  z-index: 1;
}

/* [type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
} */

/* .form-check {
  padding-left: 0;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:before {
  border: 1px solid #0fa169;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #0fa169;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
} */
.mobileusebtn {
  display: none;
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.nav-tabs .nav-link:not(.active) {
  font-size: 14px;
  color: var(--primarycolor);
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  border: none;
  padding: 0 15px !important;
}

.nav-tabs .nav-link.active {
  font-size: 14px;
  color: #009c61 !important;
}
.nav-tabs .nav-link {
  font-weight: 500;
}
.modal-lg {
  max-width: 840px;
}
.subheader .nav-tabs .nav-link.active {
  border-bottom: 1px solid #009c61 !important;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 0 0 5px 0;
}
.nav-tabs .nav-link {
  position: relative;
}
.listarea .nav-tabs .nav-link.active::after {
  content: "";
  position: absolute;
  background-color: #009c61 !important;
  height: 1px;
  left: 20px;
  right: 20px;
  bottom: -7px;
}
h4 {
  font-size: unset;
}
.page_brake {
  page-break-before: always !important ;
}
.dropdown-menu {
  right: 0 !important;
  left: auto;
  padding: 0;
  border: none;
  box-shadow: 0 20px 40px 0 rgba(146, 154, 171, 0.4);
}
.authmwidth {
  max-width: 520px;
  width: 100%;
}
.submitbtn {
  background-color: #009b61;
  color: #ffffff;
  padding: 5px 15px;
  border: none;
  border-radius: 6px;
  font-size: 13px;
}
.cancelbtn {
  background-color: transparent;
  color: #273e4c;
  padding: 5px 15px;
  border: none;
  font-size: 13px;
}
.autharea,
.landimgarea {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown-menu .dropdown-item {
  padding: 10px 15px;
  font-size: 12px;
  border-bottom: 1px solid #929aab45;
  display: flex;
  align-items: center;
}
.dropdown-menu .dropdown-item a {
  color: #504949;
}
.ql-editor.ql-blank::before {
  padding: 0 15px;
  color: #929aab !important;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500;
}
.basiceditor .ql-snow.ql-container {
  font-size: 14px !important;
}
.visualeditor .ql-snow.ql-container {
  font-size: inherit;
}
.preview-body .ql-snow.ql-container {
  font-size: inherit;
}
.editcard {
  font-size: 14px;
  color: #0e0f11;
  font-weight: 500;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(146, 154, 171, 0.2);
  background-color: #ffffff;
  cursor: pointer;
}
.editor_accordian .card-header:first-child {
  border-radius: inherit;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #ffffff;
  color: #000;
}
.bgsuccess {
  background-color: #009b61;
}
.customform,
.customform:focus {
  background-color: #ecf0f1 !important;
  border: none;
  font-size: 14px;
  /* padding: 15px 20px; */
  height: 40px !important;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="password"]:focus {
  border: solid 1px #ecf0f1;
  border-bottom: 2px solid #009c61 !important;
  background-color: #fff;
}
.formlable {
  font-size: 13px;
  color: #929aab;
}
.ql-clipboard {
  white-space: pre-wrap !important;
}
.card-title {
  font-size: 16px;
  margin: 0;
  padding: 7px 0 7px 0;
}

/*switch button*/
.switchbtn input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchbtn label {
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  margin: 0;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}
.showlable {
  float: left;
}
.cursor {
  cursor: pointer !important;
}
.switchbtn label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0px;
  width: 17px;
  height: 17px;
  background: #fff;
  border-radius: 90px;
  transition: all 0.5s ease;
}
.disable_btn {
  cursor: not-allowed !important;
}

.switchbtn input:checked + label {
  background: #009c61;
}
.switchbtn {
  float: left;
  display: inline-grid;
  margin-left: 10px;
  overflow: hidden;
}
.switchbtn input:checked + label:after {
  left: calc(100% - 0px);
  transform: translateX(-100%);
}

.switchbtn label:active:after {
  width: 130px;
}
.add-newsection {
  /* background-image: url(./assets/down-arrow.svg); */
  background-repeat: no-repeat;
  background-position: 95% 50%;
}
.rsbar .add-newsection {
  border: 1px dashed #009b61;
}
.adminprofile .admin-name::after {
  content: "";
  position: absolute;
  /* background-image: url(./assets/down-arrow.svg); */
  background-repeat: no-repeat;
  width: 20px;
  height: 30px;
  background-position: 6px;
}
.show > .add-newsection {
  /* background-image: url(./assets/up-arrow.svg); */
  background-repeat: no-repeat;
  background-position: 95% 50%;
}
.profdrpdwn {
  /* background-image: url(./assets/down-arrow.svg); */
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 12px 15px;
  font-size: 14px;
}
.profdrpdwn::after {
  border: none;
  margin-left: 10px;
}
/*Media Quaries*/
@media (max-width: 575.98px) {
  .maintitle {
    font-size: 16px;
  }
  .titledesc {
    font-size: 14px;
  }
  .socialbtn,
  .socialbtn:hover {
    margin: 10px 0 !important;
  }
  .listarea .profdrpdwn {
    margin-top: 40px;
  }

  .listarea .nav-tabs {
    margin-bottom: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .socialbtn,
  .socialbtn:hover {
    margin: 0 !important;
  }
  .listarea .nav-tabs {
    margin-bottom: 50px;
  }
}

@media (max-width: 991.98px) {
  .togglebtn {
    display: block;
  }
  .mobile-options {
    width: 15px !important;
    height: 18px !important;
    border-radius: 0 !important;
    position: absolute;
    left: 3px;
  }
  .resume-options {
    background-image: none;
    margin-right: 0 !important;
  }
  .mobileusebtn {
    display: block;
    position: fixed;
    z-index: 999999;
    width: 100%;
    bottom: 0;
  }
  .samplesidebae {
    width: 100% !important;
  }
  .resumeshowsection {
    display: none;
  }
  .navbar-collapse {
    background-color: #ffffff;
  }
  .modal-body .primarybtn {
    width: 100% !important;
    margin-bottom: 15px;
  }
  .duplicatebody {
    width: 100% !important;
  }
  .modal-body {
    padding: 40px 10px;
  }

  .resume-sidebar {
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 999;
    background-color: #ffffff;
    display: block;
    transition: all 0.5s ease;
  }
  .sidebar-hide {
    left: -100%;
  }
  .sidebar-show {
    left: 0;
  }
  .resume-sidebar .editor_accordian {
    width: 100% !important;
    background-color: #ffffff;
    top: 0px;
    padding-top: 50px;
  }
}

/* For Dropdown */
.dropdown-toggle::after {
  /* position: absolute !important;
  top: 8px; */
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgb(37, 37, 37);
  position: absolute;
  top: 50%;
  /* right: -5px; */
  content: "";
  z-index: 98;
}
#scroller {
  overflow: hidden;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) {
}

/* For Printing */

p {
  white-space: pre-wrap !important;
  overflow-wrap: break-word !important;
  resize: none;
  text-size-adjust: none !important;
  word-wrap: break-word !important;
  letter-spacing: normal !important;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
input:-webkit-autofill {
  background-color: #faffbd !important;
}

@media print {
  /* @page {
			size: A4;
			margin: 0;
		} */

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666666666666%;
  }

  .col-sm-10 {
    width: 83.33333333333334%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666666666666%;
  }

  .col-sm-7 {
    width: 58.333333333333336%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666666666667%;
  }

  .col-sm-4 {
    width: 33.33333333333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.666666666666664%;
  }

  .col-sm-1 {
    width: 8.333333333333332%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66666666666666%;
  }

  .col-md-10 {
    width: 83.33333333333334%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66666666666666%;
  }

  .col-md-7 {
    width: 58.333333333333336%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66666666666667%;
  }

  .col-md-4 {
    width: 33.33333333333333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.666666666666664%;
  }

  .col-md-1 {
    width: 8.333333333333332%;
  }

  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

/* Resume Templates page */

.resume-container {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  border: 1px solid #929aab;
  height: 267px;
}

.resume-container .actionbtns {
  position: absolute;
  display: flex;
  z-index: +1;
  display: none;
  align-items: center;
}

.resume-container .actionbtns button {
  font-weight: 400;
  color: #0e0f11;
  border-radius: 6px;
  padding: 8.5px 15px;
  font-size: 13px;
  display: flex;
}

.resume-container .overlaybtn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.6;
  display: none;
}
.resume-container:hover .overlaybtn {
  display: block;
}

.resume-container:hover .actionbtns {
  position: absolute;
  top: 0;
  /* display: block; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.resume-box {
  width: 20%;
  padding: 0 10px;
}
@media (max-width: 375.98px) {
  .resume-container {
    width: 100%;
    height: 200px;
  }
  .resume-box {
    width: 50%;
    padding: 5px;
  }
  .listarea .dropdwn-align {
    right: 0% !important;
    z-index: 999;
  }
}

@media (max-width: 469.98px) {
  .resume-container {
    width: 218px !important;
    margin: auto;
  }
  .resume-box {
    width: 100%;
    padding: 5px;
  }
  .listarea .dropdwn-align {
    right: 0% !important;
  }
}

@media (min-width: 470px) and (max-width: 575.98px) {
  .resume-container {
    width: 218px !important;
    margin: auto;
  }
  .resume-box {
    width: 50%;
    padding: 5px;
  }
  .listarea .dropdwn-align {
    right: 0% !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .resume-container {
    width: 218px !important;
    margin: auto;
  }
  .resume-box {
    width: 50%;
    padding: 5px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .resume-box {
    width: 33.33%;
    padding: 0 10px;
  }
  .resume-container {
    width: 218px !important;
  }
}
@media (min-width: 991.98px) and (max-width: 1199.98px) {
  .resume-box {
    width: 25%;
    padding: 0 10px;
  }
  .resume-container {
    width: 218px !important;
  }
}
@media (min-width: 991.98px) {
  .resume-container {
    width: 218px !important;
  }
}
/* dashboard page */

@media (max-width: 560px) {
  .listarea .dropdown-toggle::after {
    top: 75%;
  }
}
