.feedback-wrapper {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 35;
}
.feedback-para-text {
  color: rgba(110, 120, 152, 0.6);
  padding-bottom: 8px;
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
  margin: 0;
}
.feedback-email-input {
  padding: 5px 20px;
  border-radius: 4px;
  margin-bottom: 12px;
  border: 1px solid rgba(110, 120, 152, 0.15);
  box-shadow: none;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  font-weight: 500;
}
.feedback-button-div {
  button {
    padding: 5px 13px;
    background-color: #009c61;
    color: #fff;
    border: #009c61;
    border-radius: 2px;
  }
  button:focus {
    outline: none;
  }
}
.feedback-button-div:hover {
  transition: opacity 0.2s ease 0s, font-size 0.2s ease 0.2s;
}
.feedback-modal {
  position: absolute;
  width: 280px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  right: 0px;
  bottom: 50px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
@media (min-width: 470px) and (max-width: 767.98px) {
  .feedback-modal {
    width: 280px;
  }
}
@media (max-width: 470px) {
  .feedback-modal {
    width: 200px;
  }
}
.feedback-modal-type-innerdiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.feedback-modal-type-innerdiv :first-of-type {
  border-radius: 4px 0 0 4px;
}
.feedback-modal-type-innerdiv :last-of-type {
  border-radius: 0 4px 4px 0;
}
.feedback-type {
  width: 100%;
  text-align: center;
  padding: 5px;
  border: 1px solid rgba(110, 120, 152, 0.15);
  cursor: pointer;
  font-size: 14px;
}
.feedback-textarea {
  padding: 0px 10px;
  border-radius: 4px;
  margin-bottom: 12px;
  border: 1px solid rgba(110, 120, 152, 0.15);
  box-shadow: none;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  // font-weight: 500;
}
.feedback-submit-btn {
  padding: 5px 30px;
  background-color: #009c61;
  color: #fff;
}
.active-type {
  background-color: #000;
  color: #fff;
}
